import IDImage from './IDImage';
import approveApiCall from './approve';
import rejectApiCall from './reject';
import createApiCall from './create';
import detailApiCall from './detail';
import editApiCall from './edit';
import fundAccounts from './fundAccounts';
import stockAccount, { enableMutualFundAccount } from './stockAccount';
import listApiCall from './list';
import portfolio from './portfolio';
import portfolioFlex from './portfolioFlex';
import portfolioFunds from './portfolioFunds';
import createStockAccountApiCall from './createStockAccount';
import updateAccountContractNHSVApiCall from './updateAccountContractNHSV';
import stockBalanceHistoriesApiCall from './stockBalanceHistories';
import requiredCreateStockAccountApiCall from './requiredCreateStockAccount';
import requiredResyncEContractLinkApiCall from './requiredResyncEContractLink';
import checkKycApiCall from './checkKyc';
import {
  createVcamAccount,
  createVcamB2BAccount,
  resyncVcamAccountStatus,
} from './vcam';

const ApiCall = {
  list: listApiCall,
  detail: detailApiCall,
  create: createApiCall,
  edit: editApiCall,
  delete: editApiCall,
  approve: approveApiCall,
  reject: rejectApiCall,
  resyncStatus: resyncVcamAccountStatus,
  createVcamAccount,
  createVcamB2BAccount,
  IDImage,
  checkKyc: checkKycApiCall,
  fundAccounts,
  stockAccount,
  portfolio,
  portfolioFlex,
  portfolioFunds,
  updateAccountContractNHSVApiCall: updateAccountContractNHSVApiCall,
  createStockAccount: createStockAccountApiCall,
  enableMutualFundAccount,
  stockBalanceHistories: stockBalanceHistoriesApiCall,
  requiredCreateStockAccount: requiredCreateStockAccountApiCall,
  requiredResyncEContractLink: requiredResyncEContractLinkApiCall,
};

export default ApiCall;
