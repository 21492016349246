import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { Alert, Tooltip } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import { useFetchApiList } from '@stagapps/redux-utils';
import apiCall from 'apps/investment/apiCalls/user';
import {
  CreatePage,
  DetailPage,
  EditPage,
  ListPage,
} from 'common/ui/AdminCRUD/page';
import ItemForm from './Form';
import { columnsGenerator } from './config';
import ItemDetail from './detail';
import Portfolio from './portfolio';
import PortfolioItems from './portfolioItems';

export const InvestmentUserListPage = (props, { params }) => {
  const {
    data: listUsers1 = [],
    load: fetchRequiredCreateStockAccountList,
    isLoading: isLoadingRequiredCreateStockAccountList,
  } = useFetchApiList(apiCall.requiredCreateStockAccount, { resourceName: 'data' });

  const {
    data: listUsers2 = [],
    load: fetchRequiredResyncEContractLinkList,
    isLoading: isLoadingRequiredResyncEContractLinkList,
  } = useFetchApiList(apiCall.requiredResyncEContractLink, { resourceName: 'data' });

  useEffect(() => {
    fetchRequiredCreateStockAccountList();
    fetchRequiredResyncEContractLinkList();
  }, []);

  let warningMessages = [];
  if (listUsers1 && listUsers1.length > 0) {
    let listUsers1Emails = listUsers1.map(user => user.email).join('\n');

    warningMessages.push(
      <>
        <br/>
        <Alert
          message={`Có ${listUsers1.length} tài khoản đã duyệt nhưng chưa khởi tạo NHSV thành công. Vui lòng liên hệ IT để được hỗ trợ.`}
          description={
            <Tooltip title="Copied!" trigger="click">
              <a onClick={() => navigator.clipboard.writeText(listUsers1Emails)}>Copy danh sách email <CopyOutlined /></a>
            </Tooltip>
          }
          type="warning"
          showIcon
          closable
        />
      </>
    )
  }

  if (listUsers2 && listUsers2.length > 0) {
    let listUsers2Emails = listUsers2.map(user => user.email).join('\n');

    warningMessages.push(
      <>
        <br/>
        <Alert
          message={`Có ${listUsers2.length} tài khoản NHSV chưa khởi tạo eContract thành công. Vui lòng liên hệ IT để được hỗ trợ.`}
          description={
            <Tooltip title="Copied!" trigger="click">
              <a onClick={() => navigator.clipboard.writeText(listUsers2Emails)}>Copy danh sách email <CopyOutlined /></a>
            </Tooltip>
          }
          type="warning"
          showIcon
          closable
        />
      </>
    )
  }

  return (
    <>
    <ListPage
      enableSearch={true}
      title="All User Accounts"
      apiCall={apiCall}
      params={params}
      columnGenerator={props => columnsGenerator(props)}
      resourceName="data"
      createUrl={`/investment/users/create`}
      warningMessages={warningMessages}
      {...props}
    />
    </>
  );
};

export const InvestmentUserEditPage = props => {
  const { id } = useParams();

  return (
    <EditPage
      title="User"
      apiCall={apiCall}
      listUrl={`/investment/users/${id}`}
      ItemForm={ItemForm}
      options={{ title: 'Edit account info' }}
      resourceName="data"
      {...props}
    />
  );
};

export const InvestmentUserDetailPage = props => {
  return (
    <DetailPage
      title="Stag Account"
      apiCall={apiCall}
      listUrl={`/investment/users`}
      ItemDetail={ItemDetail}
      resourceName="data"
      {...props}
    />
  );
};

export const InvestmentUserCreatePage = props => (
  <CreatePage
    title="User"
    apiCall={apiCall}
    listUrl={`/investment/users`}
    ItemForm={ItemForm}
    resourceName="data"
    {...props}
  />
);

export const InvestmentUserPortfolioPage = () => (
  <Portfolio apiCall={apiCall} />
);

export const InvestmentUserPortfolioItemsPage = () => (
  <PortfolioItems apiCall={apiCall} />
);
