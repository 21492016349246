import { Tag, Button, Image, Space } from 'antd';
import { Link } from 'react-router-dom';

import { cloudflareCdn } from 'utils/image';
import {
  orderSideColors,
  orderSideLabels,
} from 'apps/investment/constants/programOrder';
import { formatCurrency, formatNumber } from 'utils/formatAmount';
import { formatDateTime } from 'utils/formatDate';
import { getChangeValueColor } from 'utils/color';

export const columnsGenerator = params => [
  {
    title: 'Khách hàng',
    dataIndex: 'user',
    render: user => (user ? `${user.name} (${user.email})` : '-'),
  },
  {
    title: 'Tổng mua',
    dataIndex: 'total_buy_amount',
    align: 'right',
    sorter: true,
    render: value => formatCurrency(value),
  },
  {
    title: 'Vốn mua',
    dataIndex: ['buy_value', 'company_buy_value'],
    align: 'right',
    sorter: true,
    render: (text, row) => (
      <>
        {' '}
        {formatCurrency(row['buy_value'])}
        <br />
        {row['company_buy_value'] > 0 && (
          <>
            (Trong đó, vốn doanh nghiệp đóng góp:{' '}
            {formatCurrency(row['company_buy_value'])} )
          </>
        )}
      </>
    ),
  },
  {
    title: 'Tiền mặt CK',
    dataIndex: 'total_cash',
    align: 'right',
    sorter: true,
    render: (value, record) => {
      return (
        <>
        <Tag style={{marginRight: 0}} color={getChangeValueColor(value)}>{formatNumber(value)}</Tag><br/>
        <br/>
        <Link to={`/investment/users/${record.user_id}/stock-balance-histories`}>Chi tiết</Link>
        </>
      );
    },
  },
  {
    title: 'ROI (%)',
    dataIndex: 'unrealized_gain_loss_percentage',
    align: 'right',
    sorter: true,
    render: value => {
      return (
        <Tag color={getChangeValueColor(value)}>{formatNumber(value)}</Tag>
      );
    },
  },
  {
    title: 'AUM',
    dataIndex: ['market_value', 'company_market_value'],
    align: 'right',
    sorter: true,
    render: (text, row) => (
      <>
        {' '}
        {formatCurrency(row['market_value'])}
        <br />
        {row['company_market_value'] > 0 && (
          <>
            (Trong đó, giá trị doanh nghiệp đóng góp:{' '}
            {formatCurrency(row['company_market_value'])} )
          </>
        )}
      </>
    ),
  },
  {
    title: 'Action',
    key: 'action',
    dataIndex: 'id',
    render: value => (
      <Button.Group>
        <Button type="primary">
          <Link to={`/investment/portfolios/${value}`}>Detail</Link>
        </Button>
      </Button.Group>
    ),
  },
];

export const portfolioFundColumns = [
  {
    title: 'Sản phẩm',
    dataIndex: 'product',
    align: 'left',
    render: object => (
      <Space>
        <Image width={51} src={object?.logo} />
        <span>{object?.code}</span>
      </Space>
    ),
  },
  {
    title: 'Số lượng',
    dataIndex: 'quantity',
    align: 'right',
    render: value => new Intl.NumberFormat('en-US').format(value),
  },
  {
    title: 'Số lượng có thể bán',
    dataIndex: 'available_quantity',
    align: 'right',
    render: value => new Intl.NumberFormat('en-US').format(value),
  },
  {
    title: 'Số lượng chờ bán',
    dataIndex: 'lock_quantity',
    align: 'right',
    render: value => new Intl.NumberFormat('en-US').format(value),
  },
  {
    title: 'Giá mua TB',
    dataIndex: 'avg_buy_price',
    align: 'right',
    render: value => new Intl.NumberFormat('en-US').format(value),
  },
  {
    title: 'Vốn mua',
    dataIndex: 'cert_subscription_amount',
    align: 'right',
    render: value => new Intl.NumberFormat('en-US').format(value),
  },
  {
    title: 'Giá trị hiện tại',
    dataIndex: 'market_value',
    align: 'right',
    render: value => new Intl.NumberFormat('en-US').format(value),
  },
  {
    title: 'Lãi lỗ chưa thực hiện',
    dataIndex: 'unrealized_gain_loss_amount',
    align: 'right',
    render: value => new Intl.NumberFormat('en-US').format(value),
  },
  {
    title: 'Hiệu quả đầu tư (%)',
    dataIndex: 'unrealized_gain_loss_percentage',
    align: 'right',
    render: value => new Intl.NumberFormat('en-US').format(value),
  },
  {
    title: 'Chương trình',
    dataIndex: 'user_program',
    align: 'left',
    render: object => (
      <Link to={`/investment/user-programs/${object?.id}`}>
        {object?.program_name}
      </Link>
    ),
  },
];

export const portfolioStockColumns = [
  {
    title: 'Sản phẩm',
    dataIndex: 'stock',
    align: 'left',
    render: object => (
      <Space>
        <Image width={50} src={object?.logo} />
        <span>
          {object?.symbol}
          {/* ({object?.name}) */}
        </span>
      </Space>
    ),
  },
  {
    title: 'Số lượng',
    dataIndex: 'quantity',
    align: 'right',
    render: value => new Intl.NumberFormat('en-US').format(value),
  },
  {
    title: 'Số lượng có thể bán',
    dataIndex: 'available_quantity',
    align: 'right',
    render: value => new Intl.NumberFormat('en-US').format(value),
  },
  {
    title: 'Số lượng chờ bán',
    dataIndex: 'lock_quantity',
    align: 'right',
    render: value => new Intl.NumberFormat('en-US').format(value),
  },
  {
    title: 'Giá mua TB',
    dataIndex: 'avg_buy_price',
    align: 'right',
    render: value => new Intl.NumberFormat('en-US').format(value),
  },
  {
    title: 'Vốn mua',
    dataIndex: 'subscription_amount',
    align: 'right',
    render: value => new Intl.NumberFormat('en-US').format(value),
  },
  {
    title: 'Giá trị thị trường',
    dataIndex: 'market_value',
    align: 'right',
    render: value => new Intl.NumberFormat('en-US').format(value),
  },
  {
    title: 'Lãi lỗ chưa thực hiện',
    dataIndex: 'unrealized_gain_loss_amount',
    align: 'right',
    render: value => new Intl.NumberFormat('en-US').format(value),
  },
  {
    title: 'Hiệu quả đầu tư (%)',
    dataIndex: 'unrealized_gain_loss_percentage',
    align: 'right',
    render: value => new Intl.NumberFormat('en-US').format(value),
  },
  {
    title: 'Chương trình',
    dataIndex: 'user_program',
    align: 'left',
    render: object => (
      <Link to={`/investment/user-programs/${object?.id}`}>
        {object?.program_name}
      </Link>
    ),
  },
];

export const portfolioFlexColumns = [
  {
    title: 'Sản phẩm',
    dataIndex: 'product',
    align: 'left',
    render: object => (
      <Space>
        <Image width={51} src={object?.logo} />
        <span>{object?.code}</span>
      </Space>
    ),
  },
  {
    title: 'Số lượng',
    dataIndex: 'quantity',
    align: 'right',
    render: value => new Intl.NumberFormat('en-US').format(value),
  },
  {
    title: 'Số lượng có thể bán',
    dataIndex: 'available_quantity',
    align: 'right',
    render: value => new Intl.NumberFormat('en-US').format(value),
  },
  {
    title: 'Số lượng chờ bán',
    dataIndex: 'lock_quantity',
    align: 'right',
    render: value => new Intl.NumberFormat('en-US').format(value),
  },
  {
    title: 'Giá mua TB',
    dataIndex: 'avg_buy_price',
    align: 'right',
    render: value => new Intl.NumberFormat('en-US').format(value),
  },
  {
    title: 'Vốn mua',
    dataIndex: 'cert_subscription_amount',
    align: 'right',
    render: value => new Intl.NumberFormat('en-US').format(value),
  },
  {
    title: 'Giá trị hiện tại',
    dataIndex: 'market_value',
    align: 'right',
    render: value => new Intl.NumberFormat('en-US').format(value),
  },
  {
    title: 'Lãi lỗ chưa thực hiện',
    dataIndex: 'unrealized_gain_loss_amount',
    align: 'right',
    render: value => new Intl.NumberFormat('en-US').format(value),
  },
  {
    title: 'Hiệu quả đầu tư (%)',
    dataIndex: 'unrealized_gain_loss_percentage',
    align: 'right',
    render: value => new Intl.NumberFormat('en-US').format(value),
  },
  {
    title: 'Chương trình',
    dataIndex: 'employee_program',
    align: 'left',
    render: object => (
      <Link to={`/investment/employee-programs/${object?.id}`}>
        {object?.code}
      </Link>
    ),
  },
  {
    title: 'Bên đóng góp',
    dataIndex: 'order_side',
    align: 'left',
    render: text => (
      <Tag color={orderSideColors[text]}>{orderSideLabels[text]}</Tag>
    ),
  },
];
